import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Area, Input, Dropdown } from '../GeneralComponents/Elements';

const MakeProduct = () => {
        const [email, setEmail] = useState('');
    
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">
                        <div className="p-6 space-y-4">





                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

MakeProduct.propTypes = {
};

const connection = connect((state) => ({}), {})(MakeProduct);
export { connection as MakeProduct };