import {apiCall} from "./actionFunctions";

export const devActions = {
    queryRoute,
    queryStripe,
}

function queryRoute(data, method, name) {
    return apiCall(method, name, method === "GET" ? {params: data} : {data} , (data, dispatch) => {
        const { message } = data;
        if(message) console.log(message)
    });
}
function queryStripe(data) {
    return apiCall("POST", "dev/stripe", {data}, (data, dispatch) => { });
}