import React, { Fragment, useEffect, useState } from "react";
import { userActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Admin = () => {
    const links = [
        { href: "/editobject", label: "Stripe" },
        { href: "/tiers", label: "Tiers" },
        { href: "/playlists", label: "Playlists" },
        { href: "/golive", label: "GoLive" },
        { href: "/emailer", label: "Emailer" },
        { href: "/product", label: "Product" },
        { href: "/shop", label: "Shop" },
    ];

    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow sm:w-4/5">
                        <div className="p-6 space-y-4 text-center">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 ">Admin Links</h1>

                            <ul className="space-y-2">
                                {links.map((link, index) => (
                                    <li key={index}>
                                        <a href={link.href} className="block px-3 py-1 text-sm text-blue-700 hover:text-blue-900 transition duration-200 ease-in-out">
                                            {link.label}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
Admin.propTypes = {};

const connection = connect((state) => ({}), {})(Admin);
export { connection as Admin };
