import React, { Fragment, useEffect, useState } from "react"
import { playlistActions } from "../../Actions"
import { connect } from "react-redux"
import ReactPaginate from "react-paginate"

const Playlists = ({ GetPlaylist, GetPlaylistById }) => {
    const [playlists, setPlaylists] = useState()

    useEffect(() => {
        const fetchPlaylistVideos = async () => {
            if (!playlists) {
                const { playlists } = await GetPlaylist()
                setPlaylists(playlists)
            }
        }
        fetchPlaylistVideos()
    }, [])

    return (
        <div className="min-h-screen">
            {playlists && playlists.length > 0 ? (
                playlists.map((playlist) => <PlaylistGroup key={playlist.id} obj={playlist} playlistId={playlist.id} GetPlaylistById={GetPlaylistById} />)
            ) : playlists && playlists.length === 0 ? (
                <div className="bg-white rounded-lg shadow h-32 p-10">
                    <p className="text-sm font-medium text-gray-800 max-w-full">
                        Your current subscription does not have access to view this page. Become a Member and view exclusive courses and seminars!
                        <a className="text-blue-500" href="/subscribe">
                            {" "}
                            Subscribe
                        </a>
                    </p>
                </div>
            ) : (
                <div className="bg-white rounded-lg shadow h-32 pt-10">
                    <p className="text-gray-700 text-center">Loading...</p>
                </div>
            )}
        </div>
    )
}

const PlaylistGroup = ({ obj, playlistId, GetPlaylistById }) => {
    const [data, setData] = useState({
        title: obj.title,
        videos: obj.videos.items.map((item) => ({
            id: item.contentDetails.videoId,
            thumbnail: item.snippet.thumbnails.medium.url,
            title: item.snippet.title,
        })),
        playlist: playlistId,
        token: obj.videos.nextPageToken,
        pageInfo: obj.videos.pageInfo,
        perPage: 6,
    })

    const fetchVideos = async () => {
        try {
            const nextPageVideos = await GetPlaylistById({ id: data.playlist, token: data.token })
            setData((prevData) => ({
                ...prevData,
                videos: [
                    ...prevData.videos,
                    ...nextPageVideos.items.map((item) => ({
                        id: item.contentDetails.videoId,
                        thumbnail: item.snippet.thumbnails.medium.url,
                        title: item.snippet.title,
                    })),
                ],
                token: nextPageVideos.nextPageToken,
            }))
        } catch (error) {
            console.error("Error fetching videos:", error)
        }
    }

    const renderVideos = () => {
        return (
            <div className="mb-8 bg-white rounded-lg p-4 shadow-lg border border-gray-200">
                <h2 className="text-2xl font-bold text-gray-800 mb-6 border-b pb-2">{data.title}</h2>
                <PaginatedItems itemsPerPage={data.perPage} items={data.videos} fetchVideos={fetchVideos} pageInfo={data.pageInfo} />
            </div>
        )
    }

    return renderVideos()
}

const Video = ({ id, thumbnail, title }) => {
    return (
        <div>
            <div className="w-48 m-2 transition-transform transform hover:scale-105 hover:rotate-3 odd:hover:rotate-[-3deg] shadow-lg">
                <div className="w-full h-full">
                    <a className="block w-full h-full" target="_blank" href={`https://www.youtube.com/watch?v=${id}`} rel="noopener noreferrer">
                        <img src={thumbnail} alt={title} className="w-full h-full object-cover" />
                    </a>
                </div>
                <div className="flex items-center text-xs px-2 py-3 h-12">
                    <p className="m-0 font-bold truncate">{title}</p>
                </div>
            </div>
        </div>
    )
}

function Items({ currentItems }) {
    return <div className="flex flex-wrap gap-4">{currentItems && currentItems.map(({ id, thumbnail, title }) => <Video key={id} id={id} thumbnail={thumbnail} title={title} />)}</div>
}

function PaginatedItems({ itemsPerPage, items, fetchVideos, pageInfo }) {
    const [itemOffset, setItemOffset] = useState(0)

    const { totalResults } = pageInfo
    const endOffset = itemOffset + itemsPerPage
    const currentItems = items.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(totalResults / itemsPerPage)

    const handlePageClick = async (event) => {
        const newOffset = event.selected * itemsPerPage
        // Fetch more videos if the requested offset exceeds the current items length
        if (newOffset >= items.length) {
            await fetchVideos()
        }
        setItemOffset(newOffset)
    }

    return (
        <div className="flex flex-col">
            <Items currentItems={currentItems} />
            <div className="m-auto">{totalResults > itemsPerPage && 
                <ReactPaginate 
                    breakLabel="..." 
                    nextLabel="Next >" 
                    previousLabel="< Previous" 
                    containerClassName="flex gap-2 mt-6" 
                    pageClassName="p-2 border rounded hidden sm:inline-block"  
                    activeClassName="bg-blue-500 text-white"
                    previousClassName="p-2 border rounded bg-gray-200" 
                    nextClassName="p-2 border rounded bg-gray-200" 
                    disabledClassName="opacity-50 cursor-not-allowed" 
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3} 
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    />
                }</div>
            
        </div>
    )
}

Playlists.propTypes = {}

const connection = connect((state) => ({}), {
    GetPlaylist: playlistActions.GetPlaylist,
    GetPlaylistById: playlistActions.GetPlaylistById,
})(Playlists)
export { connection as Playlists }
