import {apiCall} from "./actionFunctions";

export const playlistActions = {
    CreatePlaylist,
    GetPlaylist,
    GetPlaylistById,
    UpdatePlaylist,
    DeletePlaylist,
}

function CreatePlaylist(info) {
    return apiCall("POST", "playlist", {info}, (data, dispatch) => {
        const { message } = data;
    });
}
function GetPlaylist(info="") {
    return apiCall("GET", "playlist", {params: info}, (data, dispatch) => {
        const { message } = data;
    });
}
function GetPlaylistById(info="") {
    return apiCall("GET", "playlist/id", {params: info}, (data, dispatch) => {
        const { message } = data;
    });
}
function UpdatePlaylist(info) {
    return apiCall("PUT", "playlist", {info}, (data, dispatch) => {
        const { message } = data;
    });
}
function DeletePlaylist(info) {
    return apiCall("DELETE", "playlist", {info}, (data, dispatch) => {
        const { message } = data;
    });
}
