import React, { Fragment, useEffect, useState } from "react";
import { devActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Input, Button, Modal, Area, Notification } from "../GeneralComponents/Elements";
import { Textarea } from "@headlessui/react";

const DevTools = ({ queryRoute, queryStripe }) => {
    const [routeNum, setRouteNum] = useState(0);
    const [routes, setRoutes] = useState([]);
    const [activeTab, setActiveTab] = useState("routes");
    const [query, setQuery] = useState();
    const [queryResponse, setQueryResponse] = useState();

    useEffect(() => {
        setRoutes((prevRoutes) => {
            const updatedRoutes = prevRoutes || [];
            if (routeNum > updatedRoutes.length) {
                return [...updatedRoutes, ...Array(routeNum - updatedRoutes.length).fill("")];
            } else if (routeNum < updatedRoutes.length) {
                return updatedRoutes.slice(0, routeNum);
            }
            return updatedRoutes;
        });
    }, [routeNum]);

    const handleRouteChange = (value, index) => {
        setRoutes((prevRoutes) => {
            const updatedRoutes = [...(prevRoutes || [])];
            updatedRoutes[index] = value;
            return updatedRoutes;
        });
    };

    const renderRoutes = () => {
        return (
            <Fragment>
                {Array.from({ length: routeNum }).map((_, index) => (
                    <div key={index} className="border border-gray-300 p-4 rounded-md space-y-4">
                        <h1 className="text-lg font-semibold">Route {index + 1}</h1>
                        <div className="space-y-2">
                            <Input id={`route-path-${index}`} text={routes ? routes[index]?.path : ""} title="Route path" placeholder="Enter route path" required={true} onChange={(value) => handleRouteChange({ ...routes[index], path: value }, index)} />
                            <Input id={`route-method-${index}`} text={routes ? routes[index]?.method : ""} title="Method" placeholder="Enter HTTP method" required={true} onChange={(value) => handleRouteChange({ ...routes[index], method: value }, index)} />
                            <Area id={`route-data-${index}`} text={routes ? routes[index]?.data : ""} title="Data" placeholder="Enter route data" required={true} onChange={(value) => handleRouteChange({ ...routes[index], data: value }, index)} />
                        </div>
                        <div className="flex gap-2">
                            <Button id={`route-submit-${index}`} text={`Submit Route ${index + 1}`} onClick={() => submit(index)} />
                            <Button id={`route-clear-${index}`} text="Clear" onClick={() => handleRouteChange({ path: "", method: "", data: "" }, index)} />
                        </div>
                        {routes[index]?.response && (
                            <div className="mt-4 p-2 bg-gray-100 border border-gray-300 rounded-md">
                                <h2 className="text-sm font-medium text-gray-700">Response:</h2>
                                <pre className="text-xs text-gray-800 whitespace-pre-wrap break-words">{JSON.stringify(routes[index]?.response, null, 2)}</pre>
                            </div>
                        )}
                    </div>
                ))}
            </Fragment>
        );
    };

    const handleRouteNum = (amount) => {
        if (routeNum + amount >= 0) setRouteNum((prevRouteNum) => prevRouteNum + amount);
    };

    const submit = async (index = 0) => {
        if(activeTab == "routes") {
            const { data, method, path } = routes[index];
            const response = await queryRoute(data, method, path);
            handleRouteChange({ ...routes[index], response }, index);
        }
        if(activeTab == "stripe") {
            const response = await queryStripe(query);
            setQueryResponse(response)
        }
    };

    const renderContent = () => {
        switch (activeTab) {
            case "routes":
                return (
                    <div className="space-y-6">
                        <div className="flex items-center gap-4">
                            <span className="text-lg">Routes: {routeNum}</span>
                            <Button id="decrease_routes" text="-" onClick={() => handleRouteNum(-1)} />
                            <Button id="increase_routes" text="+" onClick={() => handleRouteNum(1)} />
                        </div>
                        {renderRoutes()}
                    </div>
                );
            case "stripe":
                return (
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold">Stripe</h2>
                        <p className="text-gray-700">Modify or Search Stripe.</p>
                        <Area
                            id={`query`}
                            text={query}
                            title={`Query`}
                            placeholder={`Query`}
                            required={true}
                            onChange={(value) => {
                                setQuery(value);
                            }}
                        />
                        {queryResponse && (
                            <div className="mt-4 p-2 bg-gray-100 border border-gray-300 rounded-md">
                                <h2 className="text-sm font-medium text-gray-700">Response:</h2>
                                <pre className="text-xs text-gray-800 whitespace-pre-wrap break-words">{JSON.stringify(queryResponse, null, 2)}</pre>
                            </div>
                        )}
                        <Button id={`stripe-query`} text={`Submit`} onClick={() => submit()} />
                    </div>
                );
            case "logs":
                return (
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold">Logs</h2>
                        <p className="text-gray-700">View application logs below:</p>
                        {/* Add logs display UI here */}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
        <section className="h-full p-20 min-h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
                <div className="bg-white rounded-lg shadow sm:w-4/5">
                    <div className="p-6 space-y-6">
                        <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900">Dev Toolkit</h1>
                        <div className="flex border-b border-gray-300">
                            <button
                                className={`px-4 py-2 text-lg ${activeTab === "routes" ? "border-b-2 border-blue-500 font-bold" : ""}`}
                                onClick={() => setActiveTab("routes")}
                            >
                                Routes
                            </button>
                            <button
                                className={`px-4 py-2 text-lg ${activeTab === "stripe" ? "border-b-2 border-blue-500 font-bold" : ""}`}
                                onClick={() => setActiveTab("stripe")}
                            >
                                Stripe
                            </button>
                            <button
                                className={`px-4 py-2 text-lg ${activeTab === "logs" ? "border-b-2 border-blue-500 font-bold" : ""}`}
                                onClick={() => setActiveTab("logs")}
                            >
                                Logs
                            </button>
                        </div>
                        {renderContent()}
                    </div>
                </div>
            </div>
        </section>
        </Fragment>
    );
};

DevTools.propTypes = {};

const connection = connect((state) => ({}), {
    queryRoute: devActions.queryRoute,
    queryStripe: devActions.queryStripe,
})(DevTools);
export { connection as DevTools };
