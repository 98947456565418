import React, { Fragment, useEffect, useState } from "react"
import { quickActions, tierActions, userActions } from "../../Actions"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { Modal, Input, Button } from "../GeneralComponents/Elements"
import DOMPurify from "dompurify"
import Select from "react-select"
import ReactPaginate from "react-paginate"

const Emailer = ({ SendMassEmail, GetUser }) => {
    const [html, setHtml] = useState("")
    const [subject, setSubject] = useState("")
    const [users, setUsers] = useState()
    const [userOptions, setUserOptions] = useState()
    const [emails, setEmails] = useState()
    const [selectedTiers, setSelectedTiers] = useState()
    const [selectedUsers, setSelectedUsers] = useState()
    const [selectedClearances, setSelectedClearances] = useState()
    const [showModal, setShowModal] = useState(false)
    const [selectLegacy, setSelectLegacy] = useState(false)
    const [selectLegacySubscribers, setSelectLegacySubscribers] = useState(false)
    const [selectLegacyNonSubscribers, setSelectLegacyNonSubscribers] = useState(false)
    const [selectAllUsers, setSelectAllUsers] = useState(false)
    const [numPerPage, setNumPerPage] = useState(20)
    const [isMarketing, setIsMarketing] = useState(0)

    const openModal = () => {
        setShowModal(true)
        // Scroll to the div surrounding the modal
        const modalContainer = document.getElementById("modal-container")
        if (modalContainer) {
            modalContainer.scrollIntoView({ behavior: "smooth", block: "center" })
        }
    }
    const closeModal = () => setShowModal(false)

    useEffect(() => {
        const GetUsers = async () => {
            if (!users) {
                const { users } = await GetUser()
                if (users) setUsers(users)
            }
        }
        GetUsers()
    }, [])

    useEffect(() => {
        if (users && !userOptions) {
            const formattedUsers = []
            users.forEach((user) => {
                formattedUsers.push({
                    label: user.first_name + " " + user.last_name + " (" + user.email + ")",
                    value: user.email,
                })
            })
            setUserOptions(formattedUsers)
        }
    }, [users])

    useEffect(() => {
        console.log(html)
    }, [html])

    const handleSubject = (value) => {
        setSubject(value)
    }
    useEffect(() => {
        let emailList = []
        const tierList = ["f-legacy", "f-silver", "f-gold", "f-platinum"]

        if (selectedUsers) {
            selectedUsers.forEach((user) => {
                if (!emailList.includes(user.email)) {
                    emailList.push(user.value)
                }
            })
        }
        if (selectedTiers) {
            selectedTiers.forEach((tier) => {
                users.forEach((user) => {
                    if (!emailList.includes(user.email)) {
                        if (user[tier.value] == 1) emailList.push(user.email)
                    }
                })
            })
        }
        if (selectedClearances) {
            selectedClearances.forEach((clearance) => {
                users.forEach((user) => {
                    if (!emailList.includes(user.email)) {
                        if (user.clearance == clearance.value) emailList.push(user.email)
                    }
                })
            })
        }
        if (selectLegacy) {
            users.forEach((user) => {
                if (!emailList.includes(user.email)) {
                    if (user.legacy == 1) emailList.push(user.email)
                }
            })
        }

        if (selectLegacy) {
            users.forEach((user) => {
                if (!emailList.includes(user.email)) {
                    if (user.legacy == 1) emailList.push(user.email)
                }
            })
        }
        if (selectLegacySubscribers) {
            users.forEach((user) => {
                if (!emailList.includes(user.email)) {
                    tierList.forEach((tierItem) => {
                        if (user.legacy == 1 && user[tierItem] == 1) emailList.push(user.email)
                    })
                }
            })
        }
        if (selectLegacyNonSubscribers) {
            users.forEach((user) => {
                if (!emailList.includes(user.email)) {
                    if (user.legacy == 1 && tierList.every((tier) => user[tier] === 0)) {
                        emailList.push(user.email)
                    }
                }
            })
        }
        if (selectAllUsers) {
            users.forEach((user) => {
                if (!emailList.includes(user.email)) emailList.push(user.email)
            })
        }
        setEmails(emailList)
    }, [selectedTiers, selectedUsers, selectedClearances, selectLegacy, selectLegacyNonSubscribers, selectLegacySubscribers, selectAllUsers])

    const handleCheckbox = async (e, type) => {
        switch (type) {
            case "legacy":
                setSelectLegacy(e)
                break
            case "l_subs":
                setSelectLegacySubscribers(e)
                break
            case "l_n_subs":
                setSelectLegacyNonSubscribers(e)
                break
            case "all":
                setSelectAllUsers(e)
                break
            default:
        }
    }

    const removeMarketing = (emails) => {
        if (!emails) return
        let marketableEmails = []
        emails.forEach((email) => {
            let user = users.find((user) => user.email === email)
            console.log(user)
            if (user.marketing === 1) marketableEmails.push(user.email)
        })
        return marketableEmails
    }

    const submit = async () => {
        openModal()
    }

    const sendEmails = async () => {
        const sanitizedOutput = DOMPurify.sanitize(html)
        let emailList
        if (isMarketing === 1) {
            emailList = removeMarketing(emails)
        } else {
            emailList = emails
        }
        SendMassEmail(sanitizedOutput, subject, emailList, isMarketing)
        closeModal()
    }

    const modalHtml = () => {
        return (
            <div className="w-4/5 m-auto pb-8">
                <p className="mb-8 text-md text-gray-100">
                    <strong>IMPORTANT: </strong>There is a limit for sending emails within a 24 hour period. For now, this limit is small but I will be working on increasing it. Do not send any more than 500 emails within a 24 hour time window.
                </p>

                <p className="mb-8 text-md text-gray-100">There are legal restrictions on sending users marketing email. Please ensure you are selecting the correct type of email for each email that you send. If this is a marketing email, people who have unsubscribed from marketing emails will not receive it, even if they are subscribed to you.</p>

                <p className="mb-8 text-md text-gray-100">Emails are sent slowly. This page will refresh when the emails have been sent, but you can also close the tab if you want. An email summary is sent to noreply.theoryunderground@gmail.com.</p>


                <div className="flex gap-8">
                    <div className="w-full text-center">
                        <button
                            onClick={() => setIsMarketing(1)}
                            className={`w-full py-4 text-white font-bold rounded-md transition-colors 
				${isMarketing === 1 ? "bg-blue-600" : "bg-gray-400 hover:bg-blue-500"}`}
                        >
                            Marketing
                        </button>
                        {isMarketing === 1 && <p className="text-sm text-gray-200 mt-2">Selected</p>}
                    </div>
                    <div className="w-full text-center">
                        <button
                            onClick={() => setIsMarketing(0)}
                            className={`w-full py-4 text-white font-bold rounded-md transition-colors 
				${isMarketing === 0 ? "bg-blue-600" : "bg-gray-400 hover:bg-blue-500"}`}
                        >
                            Not Marketing
                        </button>
                        {isMarketing === 0 && <p className="text-sm text-gray-200 mt-2">Selected</p>}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <div id="modal-container">
                <Modal isOpen={showModal} onClose={closeModal} Input={modalHtml} title="Email Disclaimer" desc="" buttonText="Submit" buttonFunc={sendEmails}></Modal>
            </div>

            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">
                        <div className="p-6 space-y-4">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center m-4 pb-10">Emailer</h1>

                            <div className="mb-6">
                                <div className="flex items-center space-x-4">
                                    <p className="text-lg font-semibold text-gray-700 w-1/6 pt-1">Email Subject</p>
                                    <div className="w-5/6">
                                        <Input id={`subject`} text={subject} title={``} placeholder={`Email Subject`} required={true} onChange={(value) => handleSubject(value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="p-4 py-8">
                                <p className="text-lg font-semibold text-gray-700 text-center">Write your email here.</p>
                                <ReactQuill theme="snow" value={html} onChange={setHtml} />
                            </div>

                            <div className="w-full border-t border-gray-300 pt-12 pb-4"></div>

                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center">Users</h1>

                            <div className="mb-6">
                                <div className="flex items-center space-x-4">
                                    <p className="text-lg font-semibold text-gray-700 w-1/6">Individual Users</p>
                                    <Select isMulti name="Users" options={userOptions} className="basic-multi-select rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1" classNamePrefix="select" onChange={(e) => setSelectedUsers(e)} />
                                </div>

                                <div className="flex items-center space-x-4 mt-4">
                                    <p className="text-lg font-semibold text-gray-700 w-1/6">Tiers</p>
                                    <Select
                                        isMulti
                                        name="tiers"
                                        options={[
                                            { label: "Legacy Bronze", value: "f-legacy" },
                                            { label: "Silver", value: "f-silver" },
                                            { label: "Gold", value: "f-gold" },
                                            { label: "Platinum", value: "f-platinum" },
                                        ]}
                                        className="basic-multi-select rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
                                        classNamePrefix="select"
                                        onChange={(e) => setSelectedTiers(e)}
                                    />
                                </div>

                                <div className="flex items-center space-x-4 mt-4">
                                    <p className="text-lg font-semibold text-gray-700 w-1/6">Clearance</p>
                                    <Select
                                        isMulti
                                        name="clearances"
                                        options={[
                                            { label: "Unverified", value: "unverified" },
                                            { label: "User", value: "user" },
                                            { label: "Admin", value: "admin" },
                                        ]}
                                        className="basic-multi-select rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
                                        classNamePrefix="select"
                                        onChange={(e) => setSelectedClearances(e)}
                                    />
                                </div>
                            </div>

                            <div className="flex flex-wrap gap-12 py-8 justify-center">
                                <div className="flex items-center space-x-2">
                                    <input type="checkbox" id="legacy" name="legacy_users" checked={selectLegacy} onChange={(e) => handleCheckbox(e.target.checked, "legacy")} className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500" />
                                    <label htmlFor="legacy" className="text-lg text-gray-700">
                                        Legacy Users
                                    </label>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <input type="checkbox" id="legacy_subs" name="legacy_users" checked={selectLegacySubscribers} onChange={(e) => handleCheckbox(e.target.checked, "l_subs")} className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500" />
                                    <label htmlFor="legacy_subs" className="text-lg text-gray-700">
                                        Legacy Current Subscribers
                                    </label>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <input type="checkbox" id="legacy_non_subs" name="legacy_users" checked={selectLegacyNonSubscribers} onChange={(e) => handleCheckbox(e.target.checked, "l_n_subs")} className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500" />
                                    <label htmlFor="legacy_non_subs" className="text-lg text-gray-700">
                                        Legacy Non Subscribers
                                    </label>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <input type="checkbox" id="all_users" name="legacy_users" checked={selectAllUsers} onChange={(e) => handleCheckbox(e.target.checked, "all")} className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500" />
                                    <label htmlFor="all_users" className="text-lg text-gray-700">
                                        All Users
                                    </label>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                <p className="text-md text-gray-700">Emails: {emails ? emails.length : 0}</p>
                            </div>
                            <div className="flex justify-center">
                                <div>{emails && <PaginatedItems users={users} itemsPerPage={numPerPage} items={emails}></PaginatedItems>}</div>
                            </div>

                            <div className="w-full border-t border-gray-300 pt-12 p-4"></div>

                            <div className="flex justify-center">
                                <Button id="submit" text={"Send Email"} onClick={() => submit(true)} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

function Items({ currentItems, users }) {
    const [activeTab, setActiveTab] = useState("grid")

    return (
        <div>
            {/* Tab Buttons */}
            <div className="flex gap-2 mb-4 justify-center">
                <button className={`px-4 py-2 rounded-md ${activeTab === "grid" ? "bg-blue-500 text-white" : "bg-gray-200"}`} onClick={() => setActiveTab("grid")}>
                    Grid View
                </button>
                <button className={`px-4 py-2 rounded-md ${activeTab === "table" ? "bg-blue-500 text-white" : "bg-gray-200"}`} onClick={() => setActiveTab("table")}>
                    Table View
                </button>
            </div>
            {currentItems && (
                <Fragment>
                    {activeTab === "grid" && (
                        <div className="flex flex-wrap gap-4">
                            {currentItems.map((email) => (
                                <p key={email} className="bg-blue-100 text-blue-900 p-2 rounded-md shadow-sm">
                                    {email}
                                </p>
                            ))}
                        </div>
                    )}

                    {activeTab === "table" && (
                        <div className="overflow-x-auto">
                            <table className="w-full table-auto">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-2 py-1 border">Users</th>
                                        <th className="px-2 py-1 border">Name</th>
                                        <th className="px-2 py-1 border">Customer ID</th>
                                        <th className="px-2 py-1 border">Marketing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(
                                        (user) =>
                                            currentItems.includes(user.email) && (
                                                <tr key={user.email} className="hover:bg-gray-50">
                                                    <td className="px-2 py-1 border">{user.email}</td>
                                                    <td className="px-2 py-1 border">{user.first_name + " " + user.last_name}</td>
                                                    <td className="px-2 py-1 border">{user.customer_id}</td>
                                                    <td className="px-2 py-1 border">{user.marketing === 1 ? "Receiving" : "Not Receiving"}</td>
                                                </tr>
                                            )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    )
}

function PaginatedItems({ itemsPerPage, items, users }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0)

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage
    const currentItems = items.slice(itemOffset, endOffset)
    const pageCount = Math.ceil(items.length / itemsPerPage)

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length
        setItemOffset(newOffset)
    }

    return (
        <>
            <Items currentItems={currentItems} users={users} />
            <ReactPaginate breakLabel="..." nextLabel="next >" containerClassName={"pagination"} activeClassName={"active"} previousClassName={"previous"} onPageChange={handlePageClick} pageRangeDisplayed={5} pageCount={pageCount} previousLabel="< previous" renderOnZeroPageCount={null} />
        </>
    )
}

Emailer.propTypes = {}

const connection = connect((state) => ({}), {
    GetTier: tierActions.GetTier,
    GetUser: userActions.GetUser,
    SendMassEmail: quickActions.SendMassEmail,
})(Emailer)
export { connection as Emailer }
