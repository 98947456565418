import React, { Fragment } from "react";
import { alertActions } from "../Actions";
import { connect } from "react-redux";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Alert } from "../Components/GeneralComponents";
import { Login, Account, VerifyEmail, ResetPassword, Register, OAuth, OAuthEmailer } from "../Components/Session";
import { Home, Publications, Courses, Events, Merch, Construction, Terms, Privacy, Contact, Help, UnsubEmail, Shop } from "../Components/UserPages";
import { Playlists, Tiers, Admin, GoLive, Emailer, EmailerConfigure, DevTools, Product, MakeProduct } from "../Components/Admin";
import { NavigationBar, ProtectedRoute, PageNotFound, Footer } from "../Components/GeneralComponents";
import { Subscribe, SubSuccess, Seminar, EditObject } from "../Components/Stripe";
import { SubmitFile } from "../Components/Subscriber";



import PropTypes from "prop-types";

const App = ({ alert }) => {

    const routes = [
        {
            access: ["admin"], //Admin Routes
            components: [
                { path: "/EditObject", component: <EditObject /> },
                { path: "/Playlists", component: <Playlists /> },
                { path: "/Tiers", component: <Tiers /> },
                { path: "/Admin", component: <Admin /> },
                { path: "/GoLive", component: <GoLive /> },
                { path: "/Emailer", component: <Emailer /> },
                { path: "/DevTools", component: <DevTools /> },
                { path: "/Emailer/Advanced", component: <EmailerConfigure /> },
                { path: "/Product", component: <Product /> },
                { path: "/shop", component: <Shop /> },

            ],
        },
        {
            access: ["user", "admin"], //User Routes
            components: [{ path: "/SubSuccess", component: <SubSuccess /> }],
        },
        {
            access: ["unverified", "admin"], //Unverified Routes
            components: [{ path: "/VerifyEmail/:token?", component: <VerifyEmail /> }],
        },
        {
            access: ["unverified", "user", "subscriber", "admin"], //All User Routes
            components: [{ path: "/Account", component: <Account /> }],
        },
        {
            access: [], //Unprotected Routes
            components: [
                { path: "/ResetPassword", component: <ResetPassword /> },
                { path: "/Login", component: <Login /> },
                { path: "/Register", component: <Register /> },
                { path: "/OAuth", component: <OAuth /> },
                { path: "/emailer/oauth", component: <OAuthEmailer /> },
                { path: "/Publications", component: <Publications /> },
                { path: "/Courses", component: <Courses /> },
                { path: "/Events", component: <Events /> },
                { path: "/Merch", component: <Merch /> },
                { path: "/Terms", component: <Terms /> },
                { path: "/Privacy", component: <Privacy /> },
                { path: "/terms-of-service", component: <Terms /> },
                { path: "/privacy-policy", component: <Privacy /> },
                { path: "/Contact", component: <Contact /> },
                { path: "/Subscribe", component: <Subscribe /> },
                { path: "/product/tu-subscription-tiers", component: <Subscribe /> },
                { path: "/Help", component: <Help /> },
                { path: "/unsubscribe/:encrypted/:iv", component: <UnsubEmail /> },
                { path: "/", component: <Home /> },
            ],
        },
    ];

    return (
        <Fragment>
            {process.env.REACT_APP_UNDER_CONSTRUCTION ? (
                <BrowserRouter>
                    <NavigationBar />
                    {alert && alert.message ? <Alert type={alert.type} context={alert.message}></Alert> : <Fragment></Fragment>}
                    {alert && alert.loading ? (
                        <div className="loading-container">
                            <div className="loading-text">Loading...</div>
                        </div>
                    ) : (
                        <Fragment></Fragment>
                    )}
                    <Routes>
                        {routes.map(({ access, components }, index) => components.map(({ path, component }, componentIndex) => <Route key={`${index}-${componentIndex}`} path={path} element={<ProtectedRoute allowed={access}>{component}</ProtectedRoute>} />))}
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    <Footer></Footer>
                </BrowserRouter>
            ) : (
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={<Construction />} />
                    </Routes>
                </BrowserRouter>
            )}
        </Fragment>
    );
};

App.propTypes = {
    alert: PropTypes.object,
};

function mapState(state) {
    const { alert } = state;
    return { alert };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
};

export default connect(mapState, actionCreators)(App);
