import React, { Fragment, useEffect, useState } from "react";
import { quickActions } from "../../Actions";
import { connect } from "react-redux";
import { Area, Input, Button, Modal } from "../GeneralComponents/Elements";

const EmailerConfigure = ({ AdvancedEmail1, AdvancedEmail2, AdvancedEmail3 }) => {
    const genToken = async () => {
        const response = await AdvancedEmail1()
    };
    const action2 = async () => {
        const response = await AdvancedEmail2()
    };
    const action3 = async () => {
        const response = await AdvancedEmail3()
    };
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">
                        <div className="p-6 space-y-4">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center m-4 pb-10">Emailer Configurer</h1>
                            <div className="flex gap-4 justify-center">
                            <Button id="action1" text={"Generate Email Token"} onClick={() => genToken()}></Button>
                            <Button id="action2" text={"Action 2"} onClick={() => action2()}></Button>
                            <Button id="action3" text={"Action 3"} onClick={() => action3()}></Button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

EmailerConfigure.propTypes = {};

const connection = connect((state) => ({}), {
    AdvancedEmail1: quickActions.AdvancedEmail1,
    AdvancedEmail2: quickActions.AdvancedEmail2,
    AdvancedEmail3: quickActions.AdvancedEmail3,
})(EmailerConfigure);
export { connection as EmailerConfigure };
