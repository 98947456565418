import React, { Fragment, useEffect, useState } from "react";
import { productActions, userActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Area, Input, Dropdown, Button } from "../GeneralComponents/Elements";

const Shop = ({ GetProducts, ProductCheckout }) => {
    const [productData, setProductData] = useState();
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const getProductData = async () => {
            if (!productData) {
                const { products } = await GetProducts();
                if (products) setProductData(products);
            }
        };
        getProductData();
    }, []);

    const Checkout = () => {
        console.log(cart)
        ProductCheckout(cart)
    };
    const handleCartUpdate = (product) => {
        console.log(product)
        setCart((prevCart) => [...prevCart, product.price]);
    };
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow  sm:w-4/5">
                        <div className="p-6 space-y-4 text-center">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 ">Shop</h1>
                            <div className="flex flex-wrap gap-4">
                                {productData &&
                                    productData.map((product) => (
                                        <Fragment key={product.id}>
                                            <ProductCard key={product.id} product={product} cart={cart} cartCallback={handleCartUpdate} />{" "}
                                        </Fragment>
                                    ))}
                            </div>
                            <Button id="checkout" text={"Checkout"} onClick={() => Checkout()}></Button>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

const ProductCard = ({ product, cart, cartCallback }) => {
    const { id, title, description, price, cover } = product;

    // Check if the product is in the cart
    const isInCart = cart?.some((item) => item === price);

    const handleAddToCart = () => {
        if (!isInCart) {
            cartCallback(product); // Add product to cart
        }
    };

    return (
        <div className="w-full sm:w-2/5 md:w-1/3 lg:w-1/4 border border-gray-200 rounded-lg shadow-lg overflow-hidden flex flex-col">
            <img src={cover} alt={title} className="w-full h-48 object-cover" />
            <div className="p-4 flex flex-col flex-grow">
                <h2 className="text-lg font-semibold mb-2">{title}</h2>
                <p className="text-gray-500 text-sm mb-4">{description}</p>
                <div className="mt-auto">
                    <button onClick={handleAddToCart} disabled={isInCart} className={`w-full py-2 px-4 rounded text-white ${isInCart ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"}`}>
                        {isInCart ? "In Cart" : "Add To Cart"}
                    </button>
                </div>
            </div>
        </div>
    );
};

Shop.propTypes = {};

const connection = connect((state) => ({}), {
    GetProducts: productActions.GetProducts,
    ProductCheckout: productActions.ProductCheckout
})(Shop);
export { connection as Shop };
