import React, { Fragment, useEffect, useState } from "react";
import { quickActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Area, Input, Button, Modal } from "../GeneralComponents/Elements";

const SubmitFile = ({ Submit, subContent }) => {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [picture, setPicture] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [link, setLink] = useState();

    const [subjectCount, setSubjectCount] = useState(0);
    const [messageCount, setMessageCount] = useState(0);
    const [linkCount, setLinkCount] = useState(0);

    const maxLength = {
        subject: 100,
        message: 300,
        link: 150,
    };

    const submit = () => {
        if (subjectCount <= maxLength.subject && messageCount <= maxLength.message && linkCount <= maxLength.link) {
            console.log(subject, message, link)
            Submit(subject, message, link);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length > 0) {
            setPicture(e.dataTransfer.files);
        }
    };
    function handleDragOver(ev) {
        ev.preventDefault();
    }
    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setPicture(e.target.files);
        }
    };
    function uploadFile() {
        if (picture && picture.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < picture.length; i++) {
                formData.append("files", picture[i]);
            }
            //PerformAction(formData)
        }
        closeModal();
        setPicture(null);
    }

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    const removeFile = () => setPicture(null);
    
    return (
        <Fragment>
            <h1 className="text-3xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">The TURN!</h1>

            {subContent.tiers === "f-platinum" ? (
                <Fragment>
                    <p className="text-md font-medium pb-5 text-gray-600">As a Platinum Member, you can submit a file that we will personally read and provide comments on. You can send in one file each month. Ensure your share link has the correct permissions for us to view and comment on the file.</p>

                    <div className="pb-4">
                        <Input
                            id={`subject`}
                            text={subject}
                            title={`Subject`}
                            placeholder={`A short description of your submission`}
                            required={true}
                            onChange={(value) => {
                                setSubject(value);
                                setSubjectCount(value.length);
                            }}
                        />
                        <p className="text-sm text-gray-400 text-right">
                            {subjectCount}/{maxLength.subject}
                        </p>
                        {subjectCount > maxLength.subject && <p className="text-sm text-red-400 text-right">Max character count exceeded.</p>}
                    </div>

                    <div className="pb-4">
                        <Area
                            id={`message`}
                            text={message}
                            title={`Your Message`}
                            placeholder={`A brief message about your submission`}
                            required={true}
                            onChange={(value) => {
                                setMessage(value);
                                setMessageCount(value.length);
                            }}
                        />
                        <p className="text-sm text-gray-400 text-right">
                            {messageCount}/{maxLength.message}
                        </p>
                        {messageCount > maxLength.message && <p className="text-sm text-red-400 text-right">Max character count exceeded.</p>}
                    </div>

                    <div className="pb-4">
                        <Input
                            id={`link`}
                            text={link}
                            title={`Google Doc Share Link`}
                            placeholder={`https://docs.google.com/document/d/AWd23rfd3f...`}
                            required={true}
                            onChange={(value) => {
                                setLink(value);
                                setLinkCount(value.length);
                            }}
                        />
                        <p className="text-sm text-gray-400 text-right">
                            {linkCount}/{maxLength.link}
                        </p>
                        {linkCount > maxLength.link && <p className="text-sm text-red-400 text-right">Max character count exceeded.</p>}
                    </div>

                    <div className="flex gap-2">
                        <Button id="submit" text={"Submit"} onClick={submit}></Button>
                    </div>
                </Fragment>
            ) : (
                <p className="text-sm font-medium text-gray-800 max-w-full">
                    Your current subscription does not have access to view this page. Become a Platinum Member and have your work reviewed by the core team at Theory Underground!
                    <a className="text-blue-500" href="/subscribe">
                        {" "}
                        Subscribe
                    </a>
                </p>
            )}
        </Fragment>
    );
};

SubmitFile.propTypes = {};

const connection = connect((state) => ({}), {
    Submit: quickActions.SubmitFile,
})(SubmitFile);
export { connection as SubmitFile };
