import React, { Fragment, useEffect, useState } from "react";
import { productActions, userActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import { Area, Input, Dropdown, Button, Modal } from "../GeneralComponents/Elements";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";

const Product = ({ GetProductData, CreateProduct, DeleteProduct }) => {
    const [nickname, setNickNname] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedFeature, setSelectedFeature] = useState();
    const [productOptions, setProductOptions] = useState([]);
    const [featureOptions, setFeatureOptions] = useState([]);
    const [attachments, setAttachments] = useState();
    const [html, setHtml] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState();

    const [file1, setFile1] = useState(null); // State for file1
    const [file2, setFile2] = useState(null); // State for file2
    const [coverURL, setCoverURL] = useState(""); // State for file1

    const [description, setDescription] = useState("");
    const [componentTab, setComponentTab] = useState(0);

    const [activeTab, setActiveTab] = useState(0);
    const [productData, setProductData] = useState();
    const [tabs, setTabs] = useState([
        {
            header: "Coupons",
            key: "coupons",
            type: "stripe",
        },
        {
            header: "Entitlements",
            key: "entitlements",
            type: "stripe",
        },
        {
            header: "Products",
            key: "products",
            type: "stripe",
        },
        {
            header: "Prices",
            key: "prices",
            type: "stripe",
        },
    ]);
    const openModal = () => setShowModal(true);
    const closeModal = () => {
        setShowModal(false);
        setSelectedAttachment(null);
    };
    useEffect(() => {
        const getProductData = async () => {
            if (!productData) {
                const { pData, existing } = await GetProductData({ all: true });
                if (pData) setProductData(pData);
                if (existing) setAttachments(existing);
            }
        };
        getProductData();
    }, []);

    useEffect(() => {
        if (productData && !productOptions.length && !featureOptions.length) {
            const pOptions = Object.values(productData.products.data).map((product) => ({
                value: product.id,
                label: product.name || product.id,
            }));
            setProductOptions(pOptions);
            const fOptions = Object.values(productData.entitlements.data).map((feature) => ({
                value: feature.id,
                label: feature.name || feature.id,
            }));
            setFeatureOptions(fOptions);
        }
    }, [productData]);

    const submit = () => {
        const sanitizedOutput = DOMPurify.sanitize(html);

        const formData = new FormData();
        formData.append("title", nickname);
        formData.append("description", description);
        formData.append("product", selectedProduct.value);
        formData.append("feature", selectedFeature.value);
        formData.append("message", sanitizedOutput);
        if (file1 && file1.length > 0) {
            for (let i = 0; i < file1.length; i++) {
                formData.append("cover", file1[i]);
            }
        }
        if (file2 && file2.length > 0) {
            for (let i = 0; i < file2.length; i++) {
                formData.append("attachment", file2[i]);
            }
        }

        CreateProduct(formData);
    };

    const deleteFile = (index) => {
        if (index === 1) {
            setFile1(null); // Remove file1
        } else if (index === 2) {
            setFile2(null); // Remove file2
        }
    };
    const setFiles = (files, index) => {
        if (index === 1) {
            const file = files[0]; // Ensure you're using the first file from the file array

            setFile1(files);

            // Make sure file is a valid File object
            if (file && file instanceof File) {
                const fileURL = URL.createObjectURL(file); // Create an object URL from the file
                setCoverURL(fileURL); // Store the object URL in the state
            } else {
                console.error("Invalid file object.");
            }
        } else if (index === 2) {
            setFile2(files);
        }
    };

    const renderTabs = (activeTab) => {
        return (
            <Fragment>
                {productData &&
                    tabs.map((tab) => {
                        let tabData;
                        if (productData.hasOwnProperty(tab.key)) {
                            tabData = productData[tab.key].data;
                        }

                        return <Fragment key={tab.key}>{tabData && tabs[activeTab].key == tab.key && <div>{<PaginatedItems itemsPerPage={10} items={tabData}></PaginatedItems>}</div>}</Fragment>;
                    })}
            </Fragment>
        );
    };
    const readData = () => {
        return (
            <div>
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center">Stripe</h1>
                <div className="border-b border-gray-200">
                    <ul className="flex">
                        {tabs.map((tab, index) => (
                            <li key={index} className="mr-2">
                                <button className={`inline-block p-4 border-b-2 ${activeTab === index ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`} onClick={() => setActiveTab(index)}>
                                    {tab.header}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="p-6">{renderTabs(activeTab)}</div>
            </div>
        );
    };
    const createAttachment = () => {
        return (
            <Fragment>
                <div className="mb-24">
                    <h1 className="text-xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">Create a Shop Item</h1>
                    <div className="pb-4">
                        <Input
                            id={`nickname`}
                            text={nickname}
                            title={`Title`}
                            placeholder={``}
                            required={true}
                            onChange={(value) => {
                                setNickNname(value);
                            }}
                        />
                    </div>
                    <div className="pb-4">
                        <Area
                            id={`message`}
                            text={description}
                            title={`Description`}
                            placeholder={``}
                            required={true}
                            onChange={(value) => {
                                setDescription(value);
                            }}
                        />
                    </div>

                    <div className="pb-4">
                        <h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select a Product</h1>
                        <Select name="Prices" options={productOptions} className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1" classNamePrefix="select" onChange={(e) => setSelectedProduct(e)} />
                    </div>
                    <div className="pb-4">
                        <h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select a Feature</h1>
                        <Select name="Prices" options={featureOptions} className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1" classNamePrefix="select" onChange={(e) => setSelectedFeature(e)} />
                    </div>
                    <div className="pb-4">
                        <h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Cover Image</h1>
                        <div className="p-4">
                            {file1 ? (
                                <div className="mt-2">
                                    <p>{file1[0].name}</p>
                                    <button type="button" onClick={() => deleteFile(1)} className="text-red-500">
                                        Delete
                                    </button>
                                </div>
                            ) : (
                                <FileUpload fileStateSetter={setFiles} index={1} currentFile={file1} deleteFile={deleteFile} />
                            )}
                        </div>
                    </div>

                    <div className="p-4">
                        <h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Attachment</h1>
                        {file2 ? (
                            <div className="mt-2">
                                <p>{file2[0].name}</p>
                                <button type="button" onClick={() => deleteFile(2)} className="text-red-500">
                                    Delete
                                </button>
                            </div>
                        ) : (
                            <FileUpload fileStateSetter={setFiles} index={2} currentFile={file2} deleteFile={deleteFile} />
                        )}
                    </div>

                    <div className="p-4">
                        <h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Email</h1>
                        <ReactQuill theme="snow" value={html} onChange={setHtml} />
                    </div>

                    <div className="pt-12">
                        <Button id="submit" text={"Submit"} onClick={() => submit()}></Button>
                    </div>

                    <div className="pb-4 pt-4">
                        <h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 font-bold text-center">Preview</h1>
                        <div className="flex justify-center mt-4">
                            <ProductCard title={nickname} description={description} cover={coverURL}></ProductCard>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const deleteAttachmentConfirm = (id) => {
        attachments.forEach((attachment, index) => {
            if (attachment.id === id) {
                setSelectedAttachment(attachments[index]);
            }
        });
        setShowModal(true);
    };
    const deleteWarningHTML = () => {
        return (
            <Fragment>
                <div className="w-fit m-auto">
                    <p className="mb-8 text-md text-gray-200 text-center">Are you sure you want to delete this item?</p>
                    {selectedAttachment && (
                        <ul className="space-y-4 text-sm text-gray-300 mb-12 w-fit p-12">
                            <li>
                                <strong>ID:</strong> {selectedAttachment.id}
                            </li>
                            <li>
                                <strong>Title:</strong> {selectedAttachment.title}
                            </li>
                            <li>
                                <strong>Description:</strong> {selectedAttachment.description}
                            </li>
                            <li>
                                <strong>Price:</strong> {selectedAttachment.price}
                            </li>
                            <li>
                                <strong>Product:</strong> {selectedAttachment.product}
                            </li>
                            <li>
                                <strong>Feature:</strong> {selectedAttachment.feature}
                            </li>
                            <li>
                                <strong>Cover:</strong>{" "}
                                <a href={selectedAttachment.cover} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                    {selectedAttachment.cover}
                                </a>
                            </li>
                            <li>
                                <strong>Attachment:</strong> {selectedAttachment.attachment}
                            </li>
                            <li>
                                <strong>Message:</strong> {selectedAttachment.message}
                            </li>
                        </ul>
                    )}
                </div>
            </Fragment>
        );
    };

    const deleteAttachment = () => {
        DeleteProduct(selectedAttachment)
        closeModal()
    };
    const manageProducts = () => {
        let enhancedAttachments;
        if (attachments) {
            enhancedAttachments = attachments.map((attachment) => ({
                Delete: () => deleteAttachmentConfirm(attachment.id),
                ...attachment,
            }));
        }

        return (
            <div>
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center pb-4">Manage Shop Items</h1>

                <div className="border-b border-gray-200">
                    {enhancedAttachments && attachments && (
                        <Fragment>
                            <div>
                                <PaginatedItems itemsPerPage={10} items={enhancedAttachments} />
                            </div>
                            <div>
                                {enhancedAttachments.cover && (
                                    <a href={enhancedAttachments.cover} target="_blank" rel="noopener noreferrer">
                                        {enhancedAttachments.cover}
                                    </a>
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        );
    };

    const renderComponent = () => {
        switch (componentTab) {
            case 0:
                return manageProducts();
            case 1:
                return createAttachment();
            case 2:
                return readData();
            default:
                break;
        }
    };
    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <Modal isOpen={showModal} onClose={closeModal} title="Delete Item" desc="" Input={deleteWarningHTML} buttonText="Delete" buttonFunc={deleteAttachment}></Modal>

                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow w-full">
                        <div className="p-6 space-y-4">
                            <div>
                                <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 text-center mb-4">Product Management</h1>
                                <div className="border-b border-gray-200 mb-8">
                                    <ul className="flex">
                                        <li className="mr-2">
                                            <button className={`inline-block p-4 border-b-2 ${componentTab === 0 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`} onClick={() => setComponentTab(0)}>
                                                Manage Shop Items
                                            </button>
                                            <button className={`inline-block p-4 border-b-2 ${componentTab === 1 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`} onClick={() => setComponentTab(1)}>
                                                Create Shop Item
                                            </button>
                                            <button className={`inline-block p-4 border-b-2 ${componentTab === 2 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`} onClick={() => setComponentTab(2)}>
                                                Stripe Information
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {renderComponent()}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};
function Items({ currentItems }) {
    return (
        <div className="overflow-x-auto">
            {currentItems && currentItems.length > 0 && (
                <Fragment>
                    <table className="min-w-full table-auto border-collapse">
                        <thead className="bg-gray-200">
                            <tr className="text-left text-sm font-semibold text-gray-700">
                                {Object.keys(currentItems[0]).map((key) => (
                                    <th key={key} className="px-4 py-2 border-b">
                                        {key}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, rowIndex) => (
                                <tr key={rowIndex} className={`hover:bg-gray-100 ${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                                    {Object.entries(item).map(([key, value], colIndex) => (
                                        <td key={colIndex} className="px-4 py-2 border-b text-sm text-gray-800">
                                            {typeof value === "boolean" ? (
                                                value ? (
                                                    "True"
                                                ) : (
                                                    "False"
                                                )
                                            ) : typeof value === "string" && value.startsWith("https") ? (
                                                <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                                    {value.slice(0, 15)}...
                                                </a>
                                            ) : typeof value === "function" ? (
                                                <button onClick={value} className="px-2 py-1 bg-blue-500 text-white text-xs font-medium rounded hover:bg-blue-600">
                                                    {key}
                                                </button>
                                            ) : Array.isArray(value) || typeof value === "object" ? (
                                                JSON.stringify(value)
                                            ) : (
                                                value
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Fragment>
            )}
        </div>
    );
}

function PaginatedItems({ itemsPerPage, items }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <Items currentItems={currentItems} />
            <ReactPaginate breakLabel="..." nextLabel="next >" containerClassName={"pagination"} activeClassName={"active"} previousClassName={"previous"} onPageChange={handlePageClick} pageRangeDisplayed={5} pageCount={pageCount} previousLabel="< previous" renderOnZeroPageCount={null} />
        </>
    );
}

const ProductCard = ({ title, description, cover }) => {
    return (
        <div className="w-full sm:w-2/5 md:w-1/3 lg:w-1/4 border border-gray-200 rounded-lg shadow-lg overflow-hidden flex flex-col">
            <img src={cover} alt={title} className="w-full h-48 object-cover" />
            <div className="p-4 flex flex-col flex-grow">
                <h2 className="text-lg font-semibold mb-2">{title}</h2>
                <p className="text-gray-500 text-sm mb-4">{description}</p>
                <div className="mt-auto">
                    <button className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">Add To Cart</button>
                </div>
            </div>
        </div>
    );
};

const FileUpload = ({ fileStateSetter, index }) => {
    const [files, setFiles] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files.length > 0) {
            setFiles(e.dataTransfer.files);
        }
    };

    const handleDragOver = (ev) => {
        ev.preventDefault();
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setFiles(e.target.files);
        }
    };

    useEffect(() => {
        if (files) {
            submitFile(); // Trigger submit after the files state is updated
        }
    }, [files]); // Only run submitFile when 'files' changes

    const submitFile = () => {
        if (files && fileStateSetter) {
            fileStateSetter(files, index); // Send the files to the parent via the setter function
        }
    };

    return (
        <form className="flex items-center justify-center">
            <div
                className="flex flex-col items-center justify-center w-full h-4/5 border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-800 bg-gray-200 hover:bg-gray-100 border-gray-600 hover:border-gray-500 hover:bg-gray-600"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => document.getElementById(`dropzone-file-${index}`).click()} // Trigger input click
            >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg className="w-8 h-8 mb-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                    </svg>
                    <p className="mb-2 text-sm text-gray-400">
                        <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-400">Any files (Max Size: 5MB)</p>
                </div>
            </div>
            <input id={`dropzone-file-${index}`} type="file" className="hidden" onChange={handleFileChange} />
        </form>
    );
};

Product.propTypes = {};

const connection = connect((state) => ({}), {
    GetProductData: productActions.GetProductData,
    CreateProduct: productActions.CreateProduct,
    DeleteProduct: productActions.DeleteProduct,
})(Product);
export { connection as Product };
