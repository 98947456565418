import {apiCall} from "./actionFunctions";
import { alertActions } from "../Actions";

export const stripeActions = {
    CheckoutSession,
    PortalSession,
}

function CheckoutSession(priceId, cart = []) {
    return apiCall("POST", "stripe/create-checkout-session", {priceId, cart}, (data, dispatch) => {
        const { message, url } = data;
        if(message && url) {
            dispatch(alertActions.Success(message)) 
            window.location.href = url;
        }
        console.log(message, url)
    });
}

function PortalSession(info) {
    return apiCall("POST", "stripe/create-portal-session", {info}, (data, dispatch) => {
        const { message, url } = data;
        if(message && url) {
            dispatch(alertActions.Success(message)) 
            window.location.href = url;
        }
    });
}