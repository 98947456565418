import { alertActions } from ".";
import { redux, reroute, getUrl, apiCall } from "./actionFunctions";

export const productActions = {
    GetProductData,
    CreateProduct,
    GetProducts,
    ProductCheckout,
    DeleteProduct,
};
function DeleteProduct(product) {
    return apiCall("DELETE", "product", { product }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("product"), 750);

        }
    });
}
function GetProductData(type = {}) {
    return apiCall("GET", "productData", { params: type }, (data, dispatch) => {});
}
function GetProducts(type = {}) {
    return apiCall("GET", "product", { params: type }, (data, dispatch) => {});
}
function CreateProduct(info) {
    return apiCall("POST", "product", { formData: info }, (data, dispatch) => {
        const { message } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            setTimeout(() => reroute("product"), 750);
        }
    });
}
function ProductCheckout(cart) {
    return apiCall("POST", "product/checkout", { cart }, (data, dispatch) => {
        const { message, url } = data;
        if (message) {
            dispatch(alertActions.Success(message));
            window.location.href = url;
        }
    });
}
