import { alertActions } from "../Actions";
import {redux, reroute, getUrl, apiCall} from "./actionFunctions";

export const quickActions = {
    Contact,
    SubmitFile,
    GoLive,
    GetLive,
    SendMassEmail,
    Unsubscribe,
    GetProductData,

    AdvancedEmail1,
    AdvancedEmail2,
    AdvancedEmail3,
}

function GetProductData() {
    return apiCall("GET", "productData", {}, (data, dispatch) => {});
}

function Unsubscribe(encrypted, iv) {
    return apiCall("POST", "unsubscribe", {encrypted, iv}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
        }
    });
}
function Contact(email, subject, message) {
    return apiCall("POST", "contact", {email, subject, message}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute(""), 750);
        }
    });
}
function SubmitFile(subject, message, link) {
    return apiCall("POST", "submitFile", {subject, message, link}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute("account"), 750);
        }
    });
}
function GoLive(id) {
    return apiCall("POST", "golive", {id}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute("account"), 750);
        }
    });
}
function GetLive() {
    return apiCall("GET", "getlive", {}, (data, dispatch) => {});
}

function SendMassEmail(sanitizedOutput, subject, emailList, isMarketing) {
    return apiCall("POST", "massEmail", {sanitizedOutput, subject, emailList, isMarketing}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
            setTimeout(() => reroute("emailer"), 750);
        }
    });
}

function AdvancedEmail1() {
    return apiCall("POST", "AdvancedEmail1", {}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
        }
    });
}
function AdvancedEmail2() {
    return apiCall("POST", "AdvancedEmail2", {}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
        }
    });
}

function AdvancedEmail3() {
    return apiCall("POST", "AdvancedEmail3", {}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message))
        }
    });
}
